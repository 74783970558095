import React, {useState}from 'react';
import ToastCustom from '../../components/Toast/ToastCustom';
import { Row, Col, Collapse, Button } from 'react-bootstrap';
import { FcAddDatabase, FcPackage, FcSalesPerformance, FcShop } from "react-icons/fc";
import { FaPlus } from 'react-icons/fa6';
import { getTableConfig, getTableConfigProducts } from '../Payments/TableConfig';
import PaymentCard from '../../components/Cards/PaymentCard';
import { TableCustom } from '../../components/Table/Table';

const SalePoint = () => {
    const [open, setOpen] = useState(null);
    const [loading, setLoading] = useState(false);
      const [page, setPage] = useState(1); 
    
    const data = [
        {
          nombre: "Proteína Whey Gold Standard",
          categoria: "Suplementos",
          tipo: "Proteína",
          descripcion: "24g de proteína por porción y aminoácidos esenciales.",
          tamaño: "2.27 kg",
          precio: 59.99,
          acciones: ["Ver", "Editar", "Eliminar"]
        },
        {
          nombre: "Creatina Monohidrato",
          categoria: "Suplementos",
          tipo: "Creatina",
          descripcion: "Creatina pura para aumentar la fuerza y el rendimiento durante entrenamientos intensos.",
          tamaño: "300g",
          precio: 19.99,
          acciones: ["Ver", "Editar", "Eliminar"]
        },
        {
          nombre: "BCAA Xtra",
          categoria: "Suplementos",
          tipo: "Aminoácidos",
          descripcion: "Aminoácidos de cadena ramificada para la recuperación muscular",
          tamaño: "500g",
          precio: 29.99,
          acciones: ["Ver", "Editar", "Eliminar"]
        },
        {
          nombre: "Batido de Proteína de Chocolate",
          categoria: "Bebidas",
          tipo: "Batido",
          descripcion: "Batido proteico sabor chocolate, ideal para consumir después del entrenamiento.",
          tamaño: "500ml",
          precio: 3.49,
          acciones: ["Ver", "Editar", "Eliminar"]
        },
        {
          nombre: "Isotónico Bebida Energética",
          categoria: "Bebidas",
          tipo: "Energética",
          descripcion: "Bebida isotónica para reponer electrolitos y energía durante entrenamientos largos.",
          tamaño: "600ml",
          precio: 2.49,
          acciones: ["Ver", "Editar", "Eliminar"]
        },
        {
          nombre: "Proteína Vegana en Polvo",
          categoria: "Suplementos",
          tipo: "Proteína",
          descripcion: "Proteína vegana a base de guisante y arroz, ideal para dietas sin lácteos.",
          tamaño: "1 kg",
          precio: 39.99,
          acciones: ["Ver", "Editar", "Eliminar"]
        }
      ];
      
      const handleEdit = (item) => {
        console.log('Edit product')
      };
      const handleDeleteConfirmation = (item) => {
        console.log('deleteProduct')
      };

     const renderTable = () => {
        if (loading) return <p>Cargando...</p>;
        if (!data || !data.length) return <p>No hay datos disponibles</p>;
    
        const { headers, rows } =  getTableConfigProducts(open, data, handleEdit, handleDeleteConfirmation);
        return <TableCustom headers={headers} rows={rows} page={page} setPage={setPage} />;
      };
      const handleToggle = (contentType) => {
        if (open === contentType) {
          setOpen(null);
        } else {
          //fetchData(getEndpoint(contentType));
          setOpen(contentType);
          setPage(1); // Resetea la página solo si cambias el tipo de contenido
        }
      };
    return (
        <div className="main-content">
      <ToastCustom />
      <h3>Punto de venta</h3>
      <hr className="divider" />
      <Row className="g-4">
        <ToastCustom />
        <Col md={4}>
          <PaymentCard
            title="Gestionar productos"
            text="Administra y organiza los productos disponibles"
            icon={FcShop }
            buttonText="Gestionar productos"
            onButtonClick={() => handleToggle("products")}
          />
        </Col>
        <Col md={4}>
          <PaymentCard
            title="Gestionar inventarios"
            text="Controla y supervisa el stock de productos"
            icon={FcPackage }
            buttonText="Gestionar inventarios"
            onButtonClick={() => handleToggle("inventory")}
          />
        </Col>
        <Col md={4}>
          <PaymentCard
            title="Gestionar ventas"
            text="Registra y administra las ventas realizadas"
            icon={FcSalesPerformance }
            buttonText="Gestionar ventas"
            onButtonClick={() => handleToggle("sales")}
          />
        </Col>
      </Row>
      <Collapse in={open === "products"}>
        <div className="mt-4">
          <div className="d-flex justify-content-between align-items-center">
            <h4>Productos</h4>
            <Button
              variant="danger"
              className="mt-3 d-flex align-items-center"
              onClick={() => {
                console.log('add')
              }}
            >
              <FaPlus className="mr-2" />
              Agregar
            </Button>
          </div>
          {renderTable()}
        </div>
      </Collapse>

      <Collapse in={open === "inventory"}>
        <div className="mt-4">
          <div className="d-flex justify-content-between align-items-center">
            <h4>Inventario</h4>
            <Button
              variant="danger"
              className="mt-3 d-flex align-items-center"
              onClick={() => {
              console.log('add')
              }}
            >
              <FaPlus className="mr-2" />
              Agregar
            </Button>
          </div>
          {renderTable()}
        </div>
      </Collapse>

      <Collapse in={open === "sales"}>
        <div className="mt-4">
          <div className="d-flex justify-content-between align-items-center">
            <h4>Ventas</h4>
            <Button
              variant="danger"
              className="mt-3 d-flex align-items-center"
              onClick={() => {
                console.log('add')
              }}
            >
              <FaPlus className="mr-2" />
              Agregar
            </Button>
          </div>
          {renderTable()}
        </div>
      </Collapse>
    </div>
    );

  };
  
  export default SalePoint;
  