import React from 'react';
import { Card, Button } from 'react-bootstrap';
import './PaymentCard.scss'; // Archivo SCSS para estilos específicos de la tarjeta

const PaymentCard = ({ title, text, icon: Icon, buttonText, onButtonClick }) => {
  return (
    <Card className="payment-card" onClick={onButtonClick}>
      <Card.Body>
        <div className="icon-wrapper">
          <Icon className="icon" />
        </div>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{text}</Card.Text>
        <Button  className="card-button" variant="primary" onClick={onButtonClick}>{buttonText}</Button>
      </Card.Body>
    </Card>
  );
};

export default PaymentCard;
