import Login from './views/Login/login';
import './styles/custom.scss';
//import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes } from 'react-router-dom'; // Cambiado a HashRouter
import Layout from './components/Layout';
import Home from './views/Home/Home';
import Clients from './views/Clients/Clients';
import ProtectedRoute from './ProtectedRoute';
import ForgotPassword from './views/Login/forgotPassword';
import Users from './views/Users/Users';
import ResetPassword from './views/Login/ResetPassword';
import Payments from './views/Payments/Payments';
import HistoricPayments from './views/HistoricPayments/HistoricPayments';
import SalePoint from './views/SalePoint/SalePoint';

function App() {
  return (
      <Routes>
        <Route path="/" element={<Login />} />
        {/*  <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/reset-password' element={<ResetPassword />} />*/ }
        <Route
          path="/*"
          element={
            <ProtectedRoute>
              <Layout>
                <Routes>
                  <Route path="home" element={<Home />} />
                  <Route path="clients" element={<Clients />} />
                  <Route path="sale_point" element={<SalePoint />} />
                  <Route path="inventory" element={<Clients />} />
                  <Route path="payments" element={<Payments />} />
                  <Route path="client_tracking" element={<Clients />} />
                  <Route path="users" element={<Users />} />
                  <Route path="historic_payments" element={<HistoricPayments />} />
                </Routes>
              </Layout>
            </ProtectedRoute>
          }
        />
      </Routes>
  );
}

export default App;
